var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6 col-sm-4 col-md-3 col-5",attrs:{"id":"cast-box"}},[_c('div',{staticClass:"cast-box"},[_c('button',{staticClass:"btn-like",on:{"click":function($event){return _vm.changeLikeStatus(_vm.data.user_id, _vm.data.is_favorite)}}},[(_vm.data.is_favorite === 1)?_c('img',{attrs:{"src":require('@/assets/image/icon_avatar/icon-star-like.svg'),"alt":"like"}}):_vm._e(),(_vm.data.is_favorite === 0)?_c('img',{attrs:{"src":require('@/assets/image/icon_avatar/icon-star.svg'),"alt":"like"}}):_vm._e()]),_c('router-link',{attrs:{"to":_vm.profileUrl}},[_c('div',{staticClass:"cast-box__link d-flex"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"image-avatar",attrs:{"src":_vm.imgUrl(_vm.data.image_path),"alt":_vm.data.nick_name}})]),_c('div',{staticClass:"panel-border position-absolute"},[_c('div',{staticClass:"\n              position-relative\n              w-100\n              h-100\n              d-flex\n              justify-content-center\n            "},[_c('div',{staticClass:"golf-avatar",class:{
                'golf-avatar-white':
                  _vm.rank == _vm.FEMALE_PREGO_RANK.WHITE ||
                  _vm.rank == _vm.MALE_PREGO_RANK.WHITE ||
                  _vm.rank == null,
                'golf-avatar-red':
                  _vm.rank == _vm.FEMALE_PREGO_RANK.RED ||
                  _vm.rank == _vm.MALE_PREGO_RANK.RED,
                'golf-avatar-blue':
                  _vm.rank == _vm.FEMALE_PREGO_RANK.BLUE ||
                  _vm.rank == _vm.MALE_PREGO_RANK.BLUE,
                'golf-avatar-green':
                  _vm.rank == _vm.FEMALE_PREGO_RANK.GREEN ||
                  _vm.rank == _vm.MALE_PREGO_RANK.GREEN,
                'golf-avatar-silver':
                  _vm.rank == _vm.FEMALE_PREGO_RANK.SILVER ||
                  _vm.rank == _vm.MALE_PREGO_RANK.SILVER,
                'golf-avatar-gold':
                  _vm.rank == _vm.FEMALE_PREGO_RANK.GOLD ||
                  _vm.rank == _vm.MALE_PREGO_RANK.GOLD,
                'golf-avatar-black':
                  _vm.rank == _vm.FEMALE_PREGO_RANK.BLACK ||
                  _vm.rank == _vm.MALE_PREGO_RANK.BLACK ||
                  _vm.rank == 'PG'
              }}),_c('p',{staticClass:"position-absolute best_score f-score"},[_vm._v(" "+_vm._s(_vm.data.best_score >= 160 ? 160 : _vm.data.best_score)+" ")]),_c('p',{staticClass:"position-absolute avg_score f-score",class:_vm.rank == _vm.FEMALE_PREGO_RANK.WHITE ||
                _vm.rank == _vm.MALE_PREGO_RANK.WHITE ||
                _vm.rank == _vm.FEMALE_PREGO_RANK.SILVER ||
                _vm.rank == _vm.MALE_PREGO_RANK.SILVER ||
                _vm.rank == null
                  ? 'avg_score-white'
                  : ''},[_vm._v(" "+_vm._s(_vm.data.avg_score >= 180 ? 180 : _vm.data.avg_score)+" ")])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }