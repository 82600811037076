<template>
  <div class="col-6 col-sm-4 col-md-3 col-5" id="cast-box">
    <div class="cast-box">
      <button
        class="btn-like"
        @click="changeLikeStatus(data.user_id, data.is_favorite)"
      >
        <img
          :src="require('@/assets/image/icon_avatar/icon-star-like.svg')"
          alt="like"
          v-if="data.is_favorite === 1"
        />
        <img
          :src="require('@/assets/image/icon_avatar/icon-star.svg')"
          alt="like"
          v-if="data.is_favorite === 0"
        />
      </button>
      <router-link :to="profileUrl">
        <div class="cast-box__link d-flex">
          <div class="img">
            <img
              :src="imgUrl(data.image_path)"
              :alt="data.nick_name"
              class="image-avatar"
            />
          </div>
          <div class="panel-border position-absolute">
            <div
              class="
                position-relative
                w-100
                h-100
                d-flex
                justify-content-center
              "
            >
              <div
                class="golf-avatar"
                :class="{
                  'golf-avatar-white':
                    rank == FEMALE_PREGO_RANK.WHITE ||
                    rank == MALE_PREGO_RANK.WHITE ||
                    rank == null,
                  'golf-avatar-red':
                    rank == FEMALE_PREGO_RANK.RED ||
                    rank == MALE_PREGO_RANK.RED,
                  'golf-avatar-blue':
                    rank == FEMALE_PREGO_RANK.BLUE ||
                    rank == MALE_PREGO_RANK.BLUE,
                  'golf-avatar-green':
                    rank == FEMALE_PREGO_RANK.GREEN ||
                    rank == MALE_PREGO_RANK.GREEN,
                  'golf-avatar-silver':
                    rank == FEMALE_PREGO_RANK.SILVER ||
                    rank == MALE_PREGO_RANK.SILVER,
                  'golf-avatar-gold':
                    rank == FEMALE_PREGO_RANK.GOLD ||
                    rank == MALE_PREGO_RANK.GOLD,
                  'golf-avatar-black':
                    rank == FEMALE_PREGO_RANK.BLACK ||
                    rank == MALE_PREGO_RANK.BLACK ||
                    rank == 'PG'
                }"
              />
              <p class="position-absolute best_score f-score">
                {{ data.best_score >= 160 ? 160 : data.best_score }}
              </p>
              <p
                class="position-absolute avg_score f-score"
                :class="
                  rank == FEMALE_PREGO_RANK.WHITE ||
                  rank == MALE_PREGO_RANK.WHITE ||
                  rank == FEMALE_PREGO_RANK.SILVER ||
                  rank == MALE_PREGO_RANK.SILVER ||
                  rank == null
                    ? 'avg_score-white'
                    : ''
                "
              >
                {{ data.avg_score >= 180 ? 180 : data.avg_score }}
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import imageMixin from "@/mixins/imageMixin";
import changeFavorite from "@/mixins/changeFavorite";
import { mapGetters } from "vuex";
import { MALE_PREGO_RANK, FEMALE_PREGO_RANK } from "@/utils/const";

export default {
  name: "CastBox",
  mixins: [imageMixin, changeFavorite],
  props: {
    data: {
      type: Object,
      require: false
    }
  },
  data() {
    return {
      rank: null,
      isJapanFont: false,
      FEMALE_PREGO_RANK: FEMALE_PREGO_RANK,
      MALE_PREGO_RANK: MALE_PREGO_RANK
    };
  },
  async created() {
    await this.getRank();
  },
  methods: {
    getRank() {
      if (this.user.sex === 1) {
        this.rank = this.data.female_prego_rank;
      } else {
        this.rank = this.data.male_prego_rank;
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    profileUrl() {
      if (this.user.sex === 1) {
        return "/profile/" + this.data.user_id;
      }

      return "/cast/profile/" + this.data.user_id;
    },
    imageFavorite() {
      if (this.data.is_favorite === 1) {
        return "@/assets/image/icon_avatar/icon-star-like.svg";
      }

      return "@/assets/image/icon_avatar/icon-star.svg";
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  line-height: 1;
}

a {
  color: #000;
}
.col-6 .col-sm-4 .col-md-3 {
  padding: 0 !important;
}

#cast-box {
  padding: 0;
}

.cast-box {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  color: #000;
  cursor: pointer;
  box-shadow: #26262680 2px 3px 4px;
  padding-bottom: calc(100% - 11px);

  &__link {
    height: 100%;
  }

  .img {
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    position: absolute;
    width: calc(100%);
    height: 100%;
    object-fit: cover;
    left: 0;
    img {
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
      position: absolute;
      width: calc(100%);
      height: 100%;
      object-fit: cover;
      left: 0;
      pointer-events: none;
    }
  }
  margin-right: 5.5px;
  margin-left: 5.5px;
  margin-bottom: 11px;
  flex-shrink: 0;
  .panel-border {
    right: 10px;
    bottom: 0;
    width: 44px;
    height: 60px;
    position: absolute;
    .golf-avatar {
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      background-size: contain;
      &-white {
        background-image: url("../assets/image/icon_avatar/prego-white.svg");
      }
      &-black {
        background-image: url("../assets/image/icon_avatar/prego-black.svg");
      }
      &-red {
        background-image: url("../assets/image/icon_avatar/prego-red.svg");
      }
      &-blue {
        background-image: url("../assets/image/icon_avatar/prego-blue.svg");
      }
      &-green {
        background-image: url("../assets/image/icon_avatar/prego-green.svg");
      }
      &-silver {
        background-image: url("../assets/image/icon_avatar/prego-silver.svg");
      }
      &-gold {
        background-image: url("../assets/image/icon_avatar/prego-gold.svg");
      }
    }
    .best_score {
      top: 9px;
      font-size: 16px;
      color: #686868;
    }
    .avg_score {
      bottom: 3px;
      font-size: 12px;
      transform: scale(calc(6 / 12));
      margin-bottom: 0;
      color: #fff;
      &-white {
        color: #000;
      }
    }
  }

  .btn-like {
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 2;
    background-color: transparent;
    padding: 0px;
    img {
      width: 50px;
      height: 50px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .cast-box {
    margin-right: 25px;
    margin-left: 0;
    padding-bottom: calc(100% - 25px);
    .panel-border {
      width: 48px;
      height: 68px;
      .best_score {
        top: 8px;
        font-size: 18px;
      }
      .avg_score {
        bottom: 5px;
        font-size: 12px;
        transform: scale(0.5);
        line-height: 6px;
      }
    }
    .btn-like {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
</style>
