<template>
  <AppContainer :useBackground="true">
    <div class="favorite">
      <HeaderCommon ref="header" title="お気に入り">
        <template v-slot:headerButton>
          <ButtonBack />
        </template>
      </HeaderCommon>
      <div id="content-scroll" class="content-scroll">
        <div class="favorite__list row">
          <template v-if="isLoading">
            <AppLoading />
          </template>
          <template v-else>
            <template v-if="favorites.length > 0">
              <CastBox
                v-for="(item, key) of favorites"
                :key="key"
                :data="item"
              />
            </template>
            <template v-else>
              <p class="w-100">現在データはありません。</p>
            </template>
          </template>
        </div>
        <scroll-loader :loader-method="getFavorite" :loader-disable="disable">
        </scroll-loader>
      </div>
      <CardFooter ref="footer" prefer="home" />
    </div>
  </AppContainer>
</template>

<script>
import ButtonBack from "@/views/Common/ButtonBack";
import CardFooter from "@/components/CardFooter";
import HeaderCommon from "@/views/Common/HeaderCommon";
import { mapGetters } from "vuex";
import CastBox from "../components/CastBox.vue";

export default {
  name: "Favorite",
  components: {
    ButtonBack,
    HeaderCommon,
    CastBox,
    CardFooter
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading",
      favorites: "auth/favorite"
    })
  },
  metaInfo() {
    return {
      title: "お気に入り",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      page: 1,
      perpage: 16,
      disable: false,
      params: {}
    };
  },
  async created() {
    // chagne perpage by width of device
    if (window.innerWidth < 992) {
      this.perpage = 12;
    }

    let params = {
      page: this.page,
      perpage: this.perpage,
      commit_type: "SET_FAVORITE"
    };
    await this.$store.dispatch("common/setIsLoading", true);
    await this.requestData(params);
    await this.$store.dispatch("common/setIsLoading", false);
  },
  methods: {
    async requestData(params) {
      await this.$store
        .dispatch("auth/getFavoriteList", params)
        .then(response => {
          let total = response.data.total;
          this.disable = total <= this.page * this.perpage;

          this.page++;
          this.params = params;
          if (this.page > 1) {
            this.params.page = this.page;
            this.params.commit_type = "SET_FAVORITE_LOAD_MORE";
          }
        });
    },
    getFavorite() {
      if (this.page === 1) return false;
      this.requestData(this.params);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-scroll {
  overflow-x: hidden;
  padding: 50px 13.5px 50px 13.5px;
  position: relative;
  z-index: 0;
  // height: calc(100vh - 108px);
}

.favorite {
  .header {
    .btn-back {
      position: absolute;
      left: 19px;
    }
  }

  &__list {
    padding: 10px 13.5px;
  }
}

@media only screen and (min-width: 1200px) {
  .favorite {
    .header {
      .btn-back {
        left: 35px;
      }
    }

    &__list {
      padding: 30px 10px 30px 35px;
      .cast-box {
        height: 228px;
        width: calc((100% - 75px) / 4);
        &:not(:nth-child(4n)) {
          margin-right: 25px;
        }

        &:not(:nth-last-child(-n + 4)) {
          margin-bottom: 25px;
        }
      }
    }
  }
}
</style>
