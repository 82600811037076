import service from "@/utils/axios";
import { USER_URL } from "@/api/api";

const changeFavorite = {
  methods: {
    changeLikeStatus(userId, isFavorite) {
      service({
        url: USER_URL + "/favorite",
        method: "POST",
        data: {
          user_id: userId,
          is_favorite: isFavorite === 1 ? 0 : 1
        }
      }).then(response => {
        this.$store.dispatch("auth/updateFavorite", {
          ...response.data,
          user_id: userId
        });
      });
    }
  }
};

export default changeFavorite;
